import React from 'react';
import QuillRenderer from "./QuillRenderer";

const BlogWriter = () => {
    return (
        <main className="pb-8 pb-md-11 py-6">
            <div className="container-md">
                <div className="row">
                    <div className="col-12">
                        <div className="card card-bleed shadow-light-lg mb-6">
                            <div className="card-header">
                                <h4 className="mb-0">
                                    博客写作
                                </h4>
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label">标题</label>
                                                <input className="form-control" id="name" type="text" placeholder="Title"/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label">内容</label>
                                                <QuillRenderer options={{"placeholder": "Content"}}/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-auto">
                                            <button className="btn w-100 btn-primary" type="submit">保存</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default BlogWriter;