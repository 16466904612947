import React from 'react';

const BlogContent = () => {
    return (
        <section className="pt-8 pt-md-11 pb-8 pb-md-14">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md">
                        <h1 className="display-4 mb-2">
                            博客标题
                        </h1>
                        <p className="fs-lg text-gray-700 mb-md-0">
                            作者 更新时间
                        </p>
                    </div>
                    <div className="col-auto">
                        <a href="#!" className="btn btn-primary-subtle">
                            踩一踩
                        </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <hr className="my-6 my-md-8"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p className="fs-lg text-gray-800 mb-6 mb-md-8">
                            博客内容
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlogContent;