import React from 'react';

const SearchTab = () => {
    return (
        <section className="py-6">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <form className="rounded shadow">
                            <div className="input-group input-group-lg">
                                <span className="input-group-text border-0 pe-1">
                                    <i className="fe fe-search"></i>
                                </span>
                                <input type="text" className="form-control border-0 px-1"
                                       aria-label="Search our blog..." placeholder="搜索博客"/>
                                <span className="input-group-text border-0 py-0 ps-1 pe-3">
                                    <span className="h6 text-uppercase text-body-secondary d-none d-md-block mb-0 me-5">
                                        4 篇博客
                                    </span>
                                    <button type="submit" className="btn btn-sm btn-primary">
                                        搜索
                                    </button>
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SearchTab;