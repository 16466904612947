import React from 'react';
import {Link} from "react-router-dom";
import ScrollToTopLink from "./ScrollToTopLink";

const Footer = () => {
    return (
        <div>
            <div className="position-relative">
                <div className="shape shape-bottom shape-fluid-x text-dark">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor"/>
                    </svg>
                </div>
            </div>
            <footer className="py-8 py-md-11 bg-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4 col-lg-3">
                            <img src={`${process.env.PUBLIC_URL}/img/logo_full.jpg`} alt="..." className="img-fluid mb-2"/>
                            <p className="text-gray-700 mb-2">
                                月满为望。
                            </p>
                        </div>
                        <div className="col-6 col-md-4 col-lg-2">
                            <h6 className="fw-bold text-uppercase text-gray-700">
                                产品
                            </h6>
                            <ul className="list-unstyled text-body-secondary mb-6 mb-md-8 mb-lg-0">
                                <li className="mb-3">
                                    <ScrollToTopLink to="/" className="text-reset">
                                        望日博客
                                    </ScrollToTopLink>
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-md-4 col-lg-2">
                            <h6 className="fw-bold text-uppercase text-gray-700">
                                服务
                            </h6>
                            <ul className="list-unstyled text-body-secondary mb-6 mb-md-8 mb-lg-0">
                                <li className="mb-3">
                                    <ScrollToTopLink to="/" className="text-reset">
                                        博客浏览
                                    </ScrollToTopLink>
                                </li>
                                <li className="mb-3">
                                    <ScrollToTopLink to="/blog/write" className="text-reset">
                                        博客写作
                                    </ScrollToTopLink>
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-md-4 offset-md-4 col-lg-2 offset-lg-0">
                            <h6 className="fw-bold text-uppercase text-gray-700">
                                联系作者
                            </h6>
                            <ul className="list-unstyled text-body-secondary mb-0">
                                <li className="mb-3">
                                    <a href="#!" className="text-reset">
                                        发送邮件
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-md-4 col-lg-2">
                            <h6 className="fw-bold text-uppercase text-gray-700">
                                备案信息
                            </h6>
                            <ul className="list-unstyled text-body-secondary mb-0">
                                <li className="mb-3">
                                    <a href="#!" className="text-reset">
                                        查看备案
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;