import {Link} from "react-router-dom";

const Login = () => {
    return (
        <section className="section-border border-primary">
            <div className="container d-flex flex-column">
                <div className="row align-items-center justify-content-center gx-0 min-vh-100">
                    <div className="col-12 col-md-5 col-lg-4 py-8 py-md-11">
                        <h1 className="mb-0 fw-bold text-center">
                            登录
                        </h1>
                        <p className="mb-6 text-center text-body-secondary">
                            Simplify your workflow in minutes.
                        </p>
                        <form className="mb-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="email">
                                    Email
                                </label>
                                <input type="email" className="form-control" id="email" placeholder="Email"/>
                            </div>
                            <div className="form-group mb-5">
                                <label className="form-label" htmlFor="password">
                                    密码
                                </label>
                                <input type="password" className="form-control" id="password"
                                       placeholder="密码"/>
                            </div>
                            <button className="btn w-100 btn-primary" type="submit">
                                登录
                            </button>
                        </form>
                        <p className="mb-0 fs-sm text-center text-body-secondary">
                            没有账户？点击 <Link to="/signup">注册</Link>.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;