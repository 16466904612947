import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {renderNavbar, renderNavbarDropdown} from "./RenderUtil";

const Navbar = () => {
    useEffect(() => {
        renderNavbar();
        renderNavbarDropdown();
    }, []);

    return (
        <nav className="navbar navbar-expand-lg bg-white border-bottom">
            <div className="container-fluid">
                {/* logo按键 */}
                <Link className="navbar-brand" to="/">
                    <img src={`${process.env.PUBLIC_URL}/img/logo_full.jpg`} className="navbar-brand-img" alt="..."/>
                </Link>

                {/* 折叠菜单展开按钮 */}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                {/* 折叠菜单组件 */}
                <div className="collapse navbar-collapse" id="navbarCollapse">

                    {/* 折叠菜单关闭按钮 */}
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarCollapse" aria-controls="navbarCollapse"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fe fe-x"></i>
                    </button>

                    {/* 导航列表 */}
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" id="navbarBlog" data-bs-toggle="dropdown"
                                  to="/" aria-haspopup="true" aria-expanded="false">
                                博客
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarBlog">
                                <li className="dropdown-item">
                                    <Link className="dropdown-item" to="/">
                                        浏览
                                    </Link>
                                    <Link className="dropdown-item" to="/blog/write">
                                        写作
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" id="navbarAxis" data-bs-toggle="dropdown"
                                  aria-haspopup="true" aria-expanded="false">
                                时间轴
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarAxis">
                                <li className="dropdown-item">
                                    <Link className="dropdown-item">
                                        待开发
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    {/* 登录按钮 */}
                    <Link className="navbar-btn btn btn-sm btn-primary lift ms-auto" to="/login">
                        登录
                    </Link>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
