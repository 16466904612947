import React, { useEffect } from 'react';
import Quill from 'quill';

const QuillRenderer = ({ options }) => {
    const rendererRef = React.useRef(null);

    useEffect(() => {
        if (rendererRef.current) {
            const defaultOptions = {
                modules: {
                    toolbar: [
                        ['bold', 'italic'],
                        ['link', 'blockquote', 'code', 'image'],
                        [
                            {
                                list: 'ordered',
                            },
                            {
                                list: 'bullet',
                            },
                        ],
                    ],
                },
                theme: 'snow',
            };

            const quillOptions = {
                ...defaultOptions,
                ...options,
            };

            const quill = new Quill(rendererRef.current, quillOptions);

            return () => {
                quill.enable(false);
            };
        }
    }, [options]);

    return <div ref={rendererRef} data-quill="true"></div>;
};

export default QuillRenderer;
