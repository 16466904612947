import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import BlogList from "./BlogList";
import BlogWriter from "./BlogWriter";
import BlogContent from "./BlogContent";
import SearchTab from "./SearchTab";
import MainLayout from "./MainLayout";
import SimpleLayout from "./SimpleLayout";
import Login from "./Login";
import Signup from "./Signup";
import ScrollToTop from "./ScrollToTop";

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={
                    <MainLayout>
                        <SearchTab/>
                        <BlogList/>
                    </MainLayout>
                }/>
                <Route path="/blog/write" element={
                    <MainLayout>
                        <BlogWriter/>
                    </MainLayout>
                }/>
                <Route path="/blog/content" element={
                    <MainLayout>
                        <BlogContent/>
                    </MainLayout>
                }/>
                <Route path="/login" element={
                    <SimpleLayout>
                        <Login/>
                    </SimpleLayout>
                }/>
                <Route path="/signup" element={
                    <SimpleLayout>
                        <Signup/>
                    </SimpleLayout>
                }/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
